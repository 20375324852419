"use client"

import { usePathname } from "next/navigation"
import { useStore } from "@/stores"

import { type ButtonProps } from "@/components/ui/button"
import { userlogoutAction } from "@/modules/user/actions/user-logout-action"

interface UserLogoutButtonProps extends ButtonProps {}

export function UserLogoutButton({ children, ...rest }: UserLogoutButtonProps) {
  const pathname = usePathname()
  const { setShowUploadBox, resetFiles, resetUploadJobs, resetBrand, resetCourse } = useStore((s) => {
    return {
      setShowUploadBox: s.setShowUploadBox,
      resetFiles: s.resetFiles,
      resetUploadJobs: s.resetUploadJobs,
      resetBrand: s.resetBrand,
      resetCourse: s.resetCourse,
    }
  })

  return (
    <button
      className="w-full text-start text-sm font-medium text-secondary"
      {...rest}
      onClick={async () => {
        const formData = new FormData()
        formData.append("pathname", pathname)
        setShowUploadBox(false)
        resetFiles()
        resetUploadJobs()
        resetBrand()
        resetCourse()
        await userlogoutAction()
      }}
    >
      {children}
    </button>
  )
}
