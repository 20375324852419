import { env } from "@/env.mjs"
import { type Course } from "@/types"
import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function absoluteUrl(path: string): string {
  return `${env.NEXT_PUBLIC_BASE_URL}${path}`
}

export function getHoursAndMinutesFromSeconds(seconds: number): { hours: number; minutes: number } {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return { hours, minutes }
}

export function getMinutesFromSeconds(seconds: number): number {
  return Math.floor(seconds / 60)
}

export function getTotalVideoDuration(videos: Course["videos"]): number {
  return videos.reduce((acc, video) => acc + (typeof video.video.duration === "number" ? video.video.duration : 0), 0)
}
